import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "next-i18next";
import { getLocaleLink } from "../getLocaleLink";
import { OrganizationLD, SoftwareApplicationLD } from "./schemes/cryptomusScheme";
import { WithContext } from "schema-dts";

interface IAddtionMarkup {
  displayCondition: boolean;
  jsonLD: WithContext<any>;
}

interface Props {
  title: string;
  description?: string;
  image?: string;
  url?: string;
  additionalData?: React.ReactNode;
  noIndex: boolean;
  hideMicroMarkup?: boolean;
  additionMarkup?: IAddtionMarkup[];
}

interface AlternateLink {
  href: string;
  hreflang: string;
}

const SEOHead = ({ 
  title, 
  description, 
  image, 
  url, 
  additionalData, 
  noIndex = false,
  hideMicroMarkup,
  additionMarkup
}: Props) => {
  const siteName = "https://cryptomus.com";
  const router = useRouter();
  const { asPath, locale, locales, query, route } = router;
  const { i18n } = useTranslation(); 
  const urlJSON = getLocaleLink("https://cryptomus.com/", i18n.language);
  const organizationJSON = OrganizationLD(urlJSON);
  const softwareAppJSON = SoftwareApplicationLD(urlJSON);

  const prepareMarkupScriptArray = () => {
    const microMarkupScripts: IAddtionMarkup[] = [
      {
        displayCondition: !hideMicroMarkup,
        jsonLD: organizationJSON,
      },
      {
        displayCondition: !hideMicroMarkup,
        jsonLD: softwareAppJSON,
      },
    ];

    if(!additionMarkup) return microMarkupScripts;
    return microMarkupScripts.concat(additionMarkup);
  };

  // input -> url/[slug]/ or url/[[...slug]]
  // output -> url/
  function cutSlugFromString(str) {
    const slugRegex = /\[slug\]\/?|(\[\[\.\.\.slug\]\])\/?/g;

    return str.replace(slugRegex, "");
  }

  const newRoute = cutSlugFromString(route);
  const slug = query.slug; // Get the slug from the router query

  const finalURL = `${siteName}${locale === "en" ? "" : `/${locale}`}${newRoute}${
    slug ? `${slug}` : ""
  }`;

  const alternateLangLinks: AlternateLink[] =
    locales?.map((loc) => ({
      href: `${url}/${loc}${asPath}`,
      hreflang: loc,
    })) || [];

  const alternateLinkTags = alternateLangLinks.map((alternateLink) => (
    <link
      key={alternateLink.hreflang}
      rel="alternate"
      hrefLang={alternateLink.hreflang}
      href={`${siteName}${
        alternateLink.hreflang === "en" ? "" : `/${alternateLink.hreflang}`
      }${newRoute}${slug ? `${slug}` : ""}`}
    />
  ));

  return (
    <Head>
      {/* BASE */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={finalURL} />
      <meta property="og:image:alt" content={title} />
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}

      {/* NO INDEX */}
      {noIndex && <meta name="robots" content="noindex, nofollow" />}

      {/* URL */}
      <link rel="canonical" href={finalURL} />

      {alternateLinkTags}

      {/* Extra */}
      {additionalData}

      {prepareMarkupScriptArray().map((item) => item.displayCondition 
        && <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(item.jsonLD),
          }}
        />
      )}
    </Head>
  );
};

export default SEOHead;
